<template>
  <div class="edit-company">
    <h1 class="section-header">Företagsinformation</h1>

    <validation-observer ref="companyForm" v-slot="{validated}">
      <div class="row mt-5">
        <validation-provider rules="required" v-slot="{invalid}" class="col-md-6">
          <input-field v-model="form.name" :class="{'error': invalid && validated}" label="Företagsnamn*"/>
        </validation-provider>
      </div>

      <div class="row mt-5">
        <validation-provider rules="required" v-slot="{invalid}" class="col-md-6">
          <input-field label="Adress*" :class="{'error': invalid && validated}" v-model="form.address"/>
        </validation-provider>
        <validation-provider rules="required" v-slot="{invalid}" class="col-md-6">
          <input-field label="Postnummer*" :class="{'error': invalid && validated}" v-model="form.zipcode"/>
        </validation-provider>
        <validation-provider rules="required" v-slot="{invalid}" class="col-md-6 mt-3">
          <input-field label="Ort*" :class="{'error': invalid && validated}" v-model="form.city"/>
        </validation-provider>
      </div>

      <div class="row mt-5">
        <validation-provider rules="required" v-slot="{invalid}"  class="col-md-6">
          <input-field label="Kontaktperson*" :class="{'error': invalid && validated}" v-model="form.contactPerson"/>
        </validation-provider>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <input-field label="Telefonnummer" v-model="form.phone"/>
        </div>
        <div class="col-md-6">
          <input-field label="E-post" v-model="form.email"/>
        </div>
      </div>
    </validation-observer>

    <div class="d-flex justify-content-end mt-5">
      <app-button v-if="editCompany" class="btn--red mr-3" @click="deleteCompany">Radera</app-button>
      <app-button  class="btn--secondary" @click="addCompany">
        {{ editCompany ? 'Spara' : 'Lägg till' }}
      </app-button>
    </div>
  </div>
</template>

<script>
import companiesHttp from "@/http/companies";
import {
  cancelButton,
  createSuccess,
  deleteDialogMessage,
  errorNotification,
  updateSuccess,
  yesButton
} from "@/utils/translations";
import {makeErrorMessage} from "@/utils/error.helper";

export default {
  props: ['editCompany'],
  data() {
    return {
      form: {
        name: '',
        address: '',
        city: '',
        zipcode: '',
        contactPerson: '',
        phone: '',
        email: ''
      },
      loading: false
    }
  },
  methods: {
    async addCompany() {
      const isValid = await this.$refs.companyForm.validate();
      if (!isValid) return;

      try {
        this.loading = true;

        if(this.editCompany) {
          await companiesHttp.updateCompany(this.form);
          this.$notify.success(updateSuccess('Företaget'))
        } else {
          await companiesHttp.addCompany(this.form);
          this.$notify.success(createSuccess('Företaget'));
        }

        this.$emit('companyChanged');

      } catch (e) {
        const message = makeErrorMessage(e)
        this.$notify.error(message);
      } finally {
        this.loading = false;
      }

    },
    async deleteCompany() {
      try {
        await this.$confirm('', deleteDialogMessage, {
          confirmButtonText: yesButton,
          cancelButtonText: cancelButton,
          type: 'warning'
        })
      } catch (e) {
        return;
      }

      try {
        await companiesHttp.deleteCompany(this.form._id);

        this.$notify.success('Företaget raderades!');
        this.$emit('companyChanged');
      } catch (e) {

      }
    }
  },
  created() {
    if (this.editCompany) {
      Object.assign(this.form, this.editCompany)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
