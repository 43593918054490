var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-company"},[_c('h1',{staticClass:"section-header"},[_vm._v("Företagsinformation")]),_c('validation-observer',{ref:"companyForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validated = ref.validated;
return [_c('div',{staticClass:"row mt-5"},[_c('validation-provider',{staticClass:"col-md-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('input-field',{class:{'error': invalid && validated},attrs:{"label":"Företagsnamn*"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"row mt-5"},[_c('validation-provider',{staticClass:"col-md-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('input-field',{class:{'error': invalid && validated},attrs:{"label":"Adress*"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}],null,true)}),_c('validation-provider',{staticClass:"col-md-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('input-field',{class:{'error': invalid && validated},attrs:{"label":"Postnummer*"},model:{value:(_vm.form.zipcode),callback:function ($$v) {_vm.$set(_vm.form, "zipcode", $$v)},expression:"form.zipcode"}})]}}],null,true)}),_c('validation-provider',{staticClass:"col-md-6 mt-3",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('input-field',{class:{'error': invalid && validated},attrs:{"label":"Ort*"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)})],1),_c('div',{staticClass:"row mt-5"},[_c('validation-provider',{staticClass:"col-md-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('input-field',{class:{'error': invalid && validated},attrs:{"label":"Kontaktperson*"},model:{value:(_vm.form.contactPerson),callback:function ($$v) {_vm.$set(_vm.form, "contactPerson", $$v)},expression:"form.contactPerson"}})]}}],null,true)})],1),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-6"},[_c('input-field',{attrs:{"label":"Telefonnummer"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('div',{staticClass:"col-md-6"},[_c('input-field',{attrs:{"label":"E-post"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)])]}}])}),_c('div',{staticClass:"d-flex justify-content-end mt-5"},[(_vm.editCompany)?_c('app-button',{staticClass:"btn--red mr-3",on:{"click":_vm.deleteCompany}},[_vm._v("Radera")]):_vm._e(),_c('app-button',{staticClass:"btn--secondary",on:{"click":_vm.addCompany}},[_vm._v(" "+_vm._s(_vm.editCompany ? 'Spara' : 'Lägg till')+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }