<template>
  <div class="companies">
    <app-modal @close="showModal = false; editCompany = false" v-if="showModal">
      <edit-company :edit-company="editCompany" @companyChanged="onCompanyChanged"/>
    </app-modal>

    <h1 class="section-header">Företag</h1>

    <div class="row justify-content-between mt-4">
      <div class="row col-md-8">
        <input-field v-model="search" class="col-md-3" @input="searchCompanies"  placeholder="Sök ..."></input-field>
      </div>
      <app-button class="btn--secondary mr-3" @click="showModal = true">Lägg till företag</app-button>
    </div>

    <div class="box box--outline mt-3">
      <app-table :head="head">
        <tr v-for="(company, index) in companies" :key="index">
          <td class="link-cell" @click="openEditCompanyPopup(company)">{{ company.name }}</td>
          <td>{{ company.contactPerson }}</td>
          <td>{{ company.email }}</td>
          <td>{{ company.phone }}</td>
        </tr>
      </app-table>
    </div>

    <div class="d-flex justify-content-end mt-5">
      <app-pagination
        :current-page="currentPage"
        :total-pages="totalPages"
        @pageChanged="onPageChanged"
      >
      </app-pagination>
    </div>

  </div>
</template>

<script>
import companiesHttp from '@/http/companies'
import EditCompany from "@/components/companies/EditCompany";
import AppPagination from "@/components/shared/AppPagination";

export default {
  components: {AppPagination, EditCompany},
  data() {
    return {
      showModal: false,
      companies: [],
      totalPages: 0,
      currentPage: 1,
      search: null,
      editCompany: null,
      head: [
        {name: 'Företagsnamn'},
        {name: 'Kontaktperson'},
        {name: 'E-post'},
        {name: 'Telefonnummer'}
      ]
    }
  },
  methods: {
    async onCompanyChanged() {
      await this.getCompanies()
      this.showModal = false;
      this.editCompany = null;
    },
    async getCompanies() {
      try {
        const {companies, totalCount} = await companiesHttp.getCompanies(this.currentPage, 10, this.search);
        this.companies = companies;

        this.totalPages = Math.ceil(totalCount / 10);
      } catch (e) {
      }
    },
    openEditCompanyPopup(company) {
      this.editCompany = company;
      this.showModal = true;
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.getCompanies();
    },
    searchCompanies() {
      this.currentPage = 1;
      this.getCompanies()
    }
  },
  async created() {
    await this.getCompanies();
  }
}
</script>

<style scoped>

</style>
